import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from 'flowbite-react'
import React, { useState } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'

import clsx from 'clsx'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Highlights } from 'components/auth/highlights'
import { Checkbox } from 'components/inputs/checkbox'
import { LabelInput } from 'components/inputs/input'
import { AuthOptions } from 'constants/constants'
import { Link } from 'react-router-dom'
import { getTKey } from 'utils/language'
import { isNumber } from 'utils/validate'

interface LoginProps {
	register?: UseFormRegister<any>
	errors?: FieldErrors<any>
	watch?: UseFormWatch<any>
	setValue?: UseFormSetValue<any>
	control?: Control<any, any>
	isLoading: boolean
	showHighlights?: boolean
	showSubmitButton?: boolean
	openforgotPassword?: boolean
}

export const Login: React.FC<LoginProps> = ({
	register,
	watch,
	errors,
	setValue,
	control,
	isLoading,
	openforgotPassword = false,
	showSubmitButton = true,
	showHighlights = true
}) => {
	const { t } = useTranslation()
	const tKey = getTKey('auth.login')
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [showEmailInput, setShowEmailInput] = useState(true)

	const fieldValue = watch?.('username')
	const agreement = watch?.('agreement')

	return (
		<div>
			<h2 className="font-domine text-[#123258] lg:text-3xl text-[22px] font-bold leading-10 pb-2 max-lg:text-center">
				{t(tKey('title'))}
			</h2>
			<p className="whitespace-nowrap max-lg:px-5 flex items-center gap-x-1 text-secondary font-nunito text-base font-normal leading-6 pb-4 2xl:pb-8 max-lg:text-center">
				{t(tKey('welcome'))}
				<Tooltip placement="bottom" style="light" content={t(tKey('tooltip'))}>
					<QuestionMarkCircleIcon className="cursor-pointer h-7 w-7 p-px fill-[#C7D1D9] stroke-white" />
				</Tooltip>
			</p>
			<div className="max-lg:px-5">
				<div className="flex flex-col gap-y-5">
					{!showEmailInput ? (
						<Controller
							control={control}
							name={'username'}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<div className="flex flex-col">
									<PhoneInput
										autoFormat={false}
										enableSearch={true}
										country="ch"
										placeholder={t('auth.register.placeholder.labels.enterYourPhone')}
										value={value}
										searchStyle={{
											position: 'relative',
											zIndex: '9999'
										}}
										inputStyle={{
											width: '100%',
											height: '48px',
											border: '1px solid #D3E3F1',
											boxShadow: 'none',
											outline: 'none'
										}}
										onChange={value => {
											onChange('+' + value)
											setShowEmailInput(!isNumber(value))
										}}
										inputProps={{ autoFocus: true }}
										onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
											const alphabetRegex = /^[A-Za-z]$/
											if (alphabetRegex.test(event.key)) {
												setValue?.('username', fieldValue)
												setShowEmailInput(true)
											}
										}}
									/>
									{errors?.phonenumber && (
										<p className="text-xs text-red-500 mt-1">
											{errors.phonenumber.message as string}
										</p>
									)}
								</div>
							)}
						/>
					) : (
						<Controller
							control={control}
							name={'username'}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<LabelInput
									autoComplete="true"
									type="email"
									autoFocus={true}
									value={value}
									onChange={event => {
										onChange(event.target.value)
										setShowEmailInput(
											event.target.value.startsWith('+')
												? !isNumber(event.target.value.slice(1))
												: !isNumber(event.target.value)
										)
									}}
									name="username"
									labelText={t(tKey('labels.email'))}
									errors={errors}
								/>
							)}
						/>
					)}

					<div className="relative z-0 flex items-center">
						<LabelInput
							type={isPasswordVisible ? 'text' : 'password'}
							register={register}
							errors={errors}
							autoCapitalize="false"
							autoCorrect="off"
							autoComplete="new-password"
							name="password"
							labelText={t(tKey('labels.password'))}
						/>
						<div
							onClick={() => setIsPasswordVisible(!isPasswordVisible)}
							className={clsx('absolute right-4', {
								'-translate-y-2.5': errors?.password
							})}>
							{isPasswordVisible ? (
								<EyeIcon className="w-6 h-6 stroke-primary" />
							) : (
								<EyeSlashIcon className="w-6 h-6 stroke-primary" />
							)}
						</div>
					</div>
					<div className="flex flex-col gap-y-1 justify-between">
						<Link
							to={`/auth?tab=${AuthOptions.FORGOT}`}
							target="_blank"
							className="text-[#61BC5F] text-right cursor-pointer max-lg:text-sm whitespace-nowrap">
							{t(tKey('labels.forgotPassword'))}
						</Link>
						<div className="flex items-center justify-between gap-x-4 text-xs sm:text-sm">
							<div className="text-sm gap-x-1 flex items-start ">
								<Controller
									name="agreement"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Checkbox
											name="agreement"
											value="agree"
											checked={value === 'agree'}
											onChange={e => (agreement ? onChange('') : onChange(e.target.value))}
										/>
									)}
								/>

								<label className="font-medium text-secondary xl:whitespace-nowrap">
									{t('auth.register.labels.agree')}{' '}
									<Link
										className="cursor-pointer underline text-[#61BC5F]"
										to="https://www.appointo.pro/datenschutzzentrum"
										target="__blank">
										{t('auth.register.labels.terms')}
									</Link>
									<span className="font-medium text-secondary ml-1">
										{t('auth.register.labels.and')}{' '}
									</span>
									<Link
										className="cursor-pointer underline text-[#61BC5F]"
										to="https://www.appointo.pro/datenschutzzentrum"
										target="__blank">
										{t('auth.register.labels.privacyPolicy')}
									</Link>
								</label>
							</div>
						</div>
						{errors?.agreement && (
							<p className="text-xs text-red-500">{errors.agreement.message as string}</p>
						)}
					</div>
					{showSubmitButton && (
						<div className="flex justify-center my-6">
							<Button
								className="bg-primary-green text-white w-3/5 py-3 px-0 capitalize text-sm"
								type="submit"
								disabled={isLoading}>
								{isLoading ? (
									<div className="flex items-center justify-center gap-x-5">
										<Spinner />
										<span className="animate-pulse whitespace-nowrap">
											{t(tKey('labels.pleaseWait'))}
										</span>
									</div>
								) : (
									<span>{t(tKey('labels.signIn'))}</span>
								)}
							</Button>
						</div>
					)}
				</div>
				{showSubmitButton && <></>}
			</div>
			{showHighlights && (
				<div className="max-lg:pt-0">
					<Highlights />
				</div>
			)}
		</div>
	)
}
